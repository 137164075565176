var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tbl_row" }, [
      _vm._m(0),
      _c("tbody", [
        _c("tr", [
          _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Commodity1")]),
          _c(
            "td",
            [
              _c("e-auto-complete-commodity", {
                attrs: { "ctr-cd": _vm.ctrCd, "cd-nm": _vm.commodityNm },
                on: { change: _vm.change1 },
              }),
            ],
            1
          ),
          _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Commodity2")]),
          _c(
            "td",
            [
              _c("e-auto-complete-commodity", {
                attrs: { "ctr-cd": _vm.ctrCd, "cd-nm": _vm.test2.commodityNm },
                on: { change: _vm.change2 },
              }),
            ],
            1
          ),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " " +
                _vm._s(_vm.commodityNm) +
                " (" +
                _vm._s(_vm.commodityCd) +
                ") "
            ),
          ]),
          _c("td", [
            _vm._v(
              " " +
                _vm._s(_vm.test2.commodityNm) +
                " (" +
                _vm._s(_vm.test2.commodityCd) +
                ") "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }