<template>
  <div>
    <table class="tbl_row">
      <colgroup>
        <col width="140">
        <col>
        <col width="140">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <th rowspan="2">Commodity1</th>
          <td>
            <e-auto-complete-commodity @change="change1" :ctr-cd="ctrCd" :cd-nm="commodityNm" />
          </td>
          <th rowspan="2">Commodity2</th>
          <td>
            <e-auto-complete-commodity @change="change2" :ctr-cd="ctrCd" :cd-nm="test2.commodityNm" />
          </td>
        </tr>
        <tr>
          <td>
            {{ commodityNm }} ({{ commodityCd }})
          </td>
          <td>
            {{ test2.commodityNm }} ({{ test2.commodityCd }})
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'

export default {
  name: 'SampleAutoCompleteCommodity',
  components: {
    EAutoCompleteCommodity
  },
  data: function () {
    return {
      ctrCd: 'KR',
      commodityNm: '',
      commodityCd: '',
      test2: {
        commodityNm: 'ACTIVATE DA',
        commodityCd: ''
      }
    }
  },
  methods: {
    change1 (vo) {
      this.commodityNm = vo.cdNm
      this.commodityCd = vo.cd
    },
    change2 (vo) {
      this.test2.commodityNm = vo.cdNm
      this.test2.commodityCd = vo.cd
    }
  }
}

</script>
